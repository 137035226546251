import React from "react";
import { useAuth } from "../context/AuthProvider";
import { useState, useEffect, useRef } from 'react'
import { supabase } from "../supabaseClient";
import AppNavBar from '../components/AppNavBar'
import { Container, Button, Card, Form, Modal, Row, Alert, ButtonGroup } from "react-bootstrap";
import SelectEtablissement from "../components/SelectEtablissement";
import SelectProfile from "../components/SelectProfile";
import SelectTabulation from "../components/SelectTabulation";
import { ArrowUpShort, ArrowDownShort, ArrowClockwise, ListOl, FilePlus, Trash, PencilSquare, BorderLeft, Pencil } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import IconField from '../components/IconField';
import IconPicker from "../components/IconPicker";
import InputGroup from 'react-bootstrap/InputGroup';

const MyModalForEditingTabulations = (props) => {
    const { selectedRowValues, tabulations, whenfinished } = props;
    console.log("((modalForEditTabulation))Selected Row Values = " + selectedRowValues?.id);
    const titleRef = useRef(null);
    const dbString = useRef(null);
    const multiRefEnableRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");

    const [iconSelected, setIconSelected] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    useEffect(() => {
        const fixCurrentRow = () => {
            if( selectedRowValues){
            setCurrentRow(selectedRowValues);
        }};
        fixCurrentRow();
    }, [selectedRowValues]);

    const getIconForm = () => {
        let anIcon = {};
            if(iconSelected){
                anIcon = iconSelected;
            } else if (currentRow?.iconname && currentRow?.icontype){
                anIcon = {iconName: currentRow?.iconname, iconType: currentRow?.icontype};
            } else {
                anIcon = {iconName: 'user-md', iconType: 'awsome'};
            }
            console.log("((modalForEditTabulation))Icon selected = " + anIcon.iconName + " - " + anIcon.iconType);
            return anIcon;
    }

    const handleSubmitUpdateTab = async (e) => {
        e.preventDefault();
        updateTabulation();
        if(whenfinished) whenfinished();
    }

    const updateTabulation = async () => {
        try {
            setErrorMsg("");
            setLoading(true);

            if (!titleRef.current?.value || !dbString.current?.value ) {
                setErrorMsg("Informations manquantes !");
                setLoading(false);
                return;
            }

            //Check Icon:
            let anIcon = getIconForm();

            console.log("(CreateTabulation)Create tabulations in db. ");
                let { data, error } = await supabase
                    .from('tabulations')
                    .update({ 
                            'title': titleRef.current?.value, 
                            'currentversion': 1,
                            'dbstring': dbString.current?.value,
                            'iconname': anIcon.iconName,
                            'icontype': anIcon.iconType,
                            'usemultiref': multiRefEnableRef.current?.checked,
                         })
                    .eq('id', currentRow.id)
                    .select()
    
                if (error) {
                    console.warn(error)
                } else if (data) {
                    console.log(data);
                }
            
        } catch (error) {
            setErrorMsg(error);
        }
        setLoading(false);
    };


    return (
        <>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Editer une tabulation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmitUpdateTab}>
                    <Row className="mb-3">

                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon2">Titre</InputGroup.Text>
                        <Form.Control type="text" ref={titleRef} defaultValue={currentRow ? currentRow?.title : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon2">DbString</InputGroup.Text>
                        <Form.Control type="text" ref={dbString} defaultValue={currentRow ? currentRow?.dbstring : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        <Form.Check type="switch" ref={multiRefEnableRef} defaultChecked={currentRow ? currentRow?.usemultiref : true} />
                        <Form.Check.Label>Active les références sur l'annuaire principal</Form.Check.Label>
                        </InputGroup>

                        <IconPicker callback={ 
                            (icon) => {
                                console.log("Choosed " + icon.iconName + " - " +icon.iconType);
                                setIconSelected(icon);
                            } 
                            } initialIcon={getIconForm()}/>
                    </Row>
                    {errorMsg && (
                        <Alert
                            variant="danger"
                            onClose={() => setErrorMsg("")}
                            dismissible>
                            {errorMsg}
                        </Alert>
                    )}
                    <div className="text-center mt-2">
                        <ButtonGroup>
                            <Button disabled={loading} type="submit" className="w-50"><FilePlus size={32}/>Modifier</Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
        </>

    );
}

export default MyModalForEditingTabulations;