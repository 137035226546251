import './App.css';
import Account from './pages/account'
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
//import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import AuthRoute from "./components/AuthRoutes";
import Home from "./pages/home";
import UserList from "./pages/userlist";
import AuthorizedUserList from "./pages/authorizedlist";
import Phonebook from './pages/phonebook';
import PhonebookProfiles from './pages/phonebookProfiles';
import Tabulations from './pages/tabulations';
import TabulationsList from './pages/tabulationsList.js';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
<>
        <div>
          <Routes>
            <Route element={<AuthRoute />}>
              <Route path="/" element={<Phonebook />} />
              <Route path="/phonebook" element={<Phonebook />} />
              <Route path="/phonebook_profiles" element={<PhonebookProfiles />} />
              <Route path="/account" element={<Account />} />
              {/* <Route path="/home" element={<Home />} /> */}
              <Route path="/userlist" element={<UserList />} />
              <Route path="/authorizeduserlist" element={<AuthorizedUserList />} />
              <Route path="/tabulations" element={<Tabulations/>} />
              <Route path="/tabulationslist" element={<TabulationsList/>} />
            </Route>
            <Route path="/login" element={<SignIn/>} />
            <Route path="/signup" element={<SignUp/>} />
          </Routes>
        </div>
    </>
  );
}

export default App;
