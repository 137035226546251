import { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import AppNavBar from '../components/AppNavBar'
import Container from 'react-bootstrap/Container';
import { useAuth } from "../context/AuthProvider";
import { Card } from "react-bootstrap";

import ListeAnnuaire from '../test.json';

export default function Account() {
  const [loading, setLoading] = useState(true)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [avatar_url, setAvatarUrl] = useState(null)
  const [etablissement, setEtablissement] = useState(null);
  const [profile, setProfile] = useState(null);
  const { user, auth } = useAuth();


  useEffect(() => {
    async function getProfile() {
      setLoading(true)
      let { data, error } = await supabase
        .from('profiles')
        .select('first_name, last_name, avatar_url, etablissement, profile')
        .eq('id', user.id)
        .single()

      if (error) {
        console.warn(error)
      } else if (data) {
        console.log(data);
        console.log(data.first_name);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setAvatarUrl(data.avatar_url);
        setEtablissement(data.etablissement);
        setProfile(data.profile);
      }

    console.log(user?.app_metadata.claims_admin);
      console.log(user)
      console.log(auth);

      setLoading(false);
    }

    console.log("Nb items dans l'annuaire : " + ListeAnnuaire.length);

    getProfile();
  }, []);

  async function updateProfile(event, avatarUrl) {
    event.preventDefault()

    setLoading(true);

    const updates = {
      id: user.id,
      first_name: firstName,
      last_name: lastName,
      avatarUrl,
      updated_at: new Date(),
    }

    let { error } = await supabase.from('profiles').upsert(updates)

    if (error) {
      alert(error.message)
    } else {
      setAvatarUrl(avatarUrl)
    }
    setLoading(false)
  }


  
  return (
    <div>

    <AppNavBar />
    <Container>
    <Card>
    <Card.Title>Mise à jour du profil</Card.Title>
        <Card.Body>
    <form onSubmit={updateProfile} className="form-widget">
      <div>
        <label htmlFor="email">Email</label>
        <input id="email" type="text" value={user.email} disabled />
      </div>
      <div>
        <label htmlFor="firstName">Prénom</label>
        <input
          id="firstName"
          type="text"
          required
          value={ firstName || ''}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="lastName">Nom</label>
        <input
          id="lastName"
          type="text"
          required
          value={lastName || ''}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div>
        <s1>Etablissement : {etablissement}</s1>
      </div>
      <div>
        <s1>Profile : {profile}</s1>
      </div>
      <div>
        <s1>Avatar : {avatar_url}</s1>
      </div>
    {user?.app_metadata.claims_admin ? "Admin" : null}
      <div>
        <button className="button block primary" type="submit" disabled={loading}>
          {loading ? 'Loading ...' : 'Update'}
        </button>
      </div>

    </form>
    </Card.Body>
    </Card>
    </Container>
    </div>


  )
}