import React from "react";
import { useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { supabase } from "../supabaseClient";
import AppNavBar from '../components/AppNavBar'
import { Container, Button, Form, Modal, Row, Col } from "react-bootstrap";
import SelectEtablissement from "../components/SelectEtablissement";
import SelectProfile from "../components/SelectProfile";
import SelectTabulation from "../components/SelectTabulation";
import { ArrowUpShort, ArrowDownShort, ArrowClockwise, ListOl, FilePlus, Trash, PencilSquare } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import IconField from '../components/IconField';
import IconPicker from "../components/IconPicker";
import ModalForEditProfileMR from './modalForEditProfileMR';
import ModalForCreateProfileMR from './modalForCreateProfileMR';

const PhonebookProfiles = () => {
    const [loading, setLoading] = useState(false)
    const [phonebookProfiles, setPhonebookProfiles] = useState(null);
    const [etablissementId, setEtablissementId] = useState();
    const [etablissement, setEtablissement] = useState();
    const [totalSize, setTotalSize] = useState(0);
    const [selectedRow, setSelectedRow] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalForEditShow, setModalForEditShow] = useState(false);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(1);

    /**
         * Get the size of the profile table when the table is updated
         */
    const getTotalSize = async () => {
        //Get total size
        if (etablissementId !== undefined) {
            setLoading(true)
            console.log("Get total size of phonebook in db with " + etablissementId);
            let { data, error, count } = await supabase.from('phonebook_profiles')
                .select('*,etablissement(titre, id)', { count: 'exact', head: true })
                .eq('etablissement.id', etablissementId);
            console.log("Total size = " + count + " - " + data + " - " + error);
            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
            }
            setTotalSize(count);
            console.log("Total size = " + count);
            setLoading(false);
        } else {
            console.log("Error in getPhonebookProfiles - etablissement " + etablissementId);
        }

    }

    /**
         * Get the phonebook table from the database with pagination
         * @param {*} firstIndex 
         * @param {*} lastIndex 
         */
    const getPhonebookProfiles = async (firstIndex, lastIndex) => {

        //Get the total size of the profile table for pagination
        getTotalSize();

        setLoading(true)
        console.log("(Phonebook profiles)Get phonebook in db with " + etablissementId);
        if (etablissementId !== undefined) {
            let { data, error } = await supabase
                .from('phonebook_profiles')
                .select('*,etablissement!inner(titre, id, email)')
                .eq('etablissement.id', etablissementId)
                // .order('ordre', { ascending: true })
                .range(firstIndex, lastIndex)

            if (error) {
                console.warn(error)
            } else if (data) {
                //console.log(data);
                data.map((item, index) => { item["num"] = firstIndex + index + 1; return item; });
                setPhonebookProfiles(data);
                console.log(data);
            }
        } else {
            console.log("Error in getPhonebook - etablissement " + etablissementId + " is undefined");
        }

        setLoading(false);
    }

    const updateTablePage = () => {
        const currentIndex = (page - 1) * sizePerPage;
        getPhonebookProfiles(currentIndex, currentIndex + sizePerPage - 1);
    };

    useEffect(() => {
        updateTablePage();
    }, [etablissementId]);

    /**
            * Update one value of a row
            * @param {*} itemId Id of the order to update
            * @param {*} dataField Data field to update
            * @param {*} newOrderValue New value of the order
            */
    const updateInPhonebook = async (itemId, dataField, newOrderValue) => {

/*         setLoading(true)
        console.log("*Update phonebook in db with " + etablissement + "in profile " + profile + " in tabulation " + tabulation);
        console.log("ItemID = " + itemId + "in row = " + dataField + "and value = " + newOrderValue);
        if (etablissement !== undefined || profile !== undefined || tabulation !== undefined) {
            if(dataField === "numero_long_court"){
                console.log("Update numero_long_court = " + newOrderValue);
            }else {
                let { data, error } = await supabase
                .from('phonebook_profiles')
                .update({ [dataField]: newOrderValue })
                .eq('etablissement', etablissement)
                .eq('profile', profile)
                .eq('tabulation', tabulation)
                .eq('id', itemId)
                .select()

                if (error) {
                    console.warn(error)
                } else if (data) {
                    console.log(data);
                }
            }

            updateTablePage();
        } else {
            console.log("Error in Updating " + dataField + " in Phonebook - etablissement " + etablissement + " or profile " + profile + " or tabulation " + tabulation + " is undefined");
        }
        setLoading(false);
 */    }

    /**
     * Remove id from the selected row
     * @param {*} id
     * @returns
     * 
     */
    const removeIdFromSelectedRow = async (id) => {
        setSelectedRow(row => row.filter(item => item.id !== id));


    }

    /**
            * Delete one row in phonebook
            */
    const deleteItemInPhonebookProfiles = async () => {
        console.log("Delete phonebook profile " + `${selectedRow[0]}` + " in db with in etablissement " + etablissementId);
        console.log("Selected row = " + selectedRow.length);
        setLoading(true)
        if (selectedRow.length > 0) {
            if (etablissementId !== undefined) {
                console.log("Try to delete phonebook profile in profile multiref " + `${selectedRow[0]}` + " in db with in etablissement " + etablissementId);
                const response = await supabase
                    .from('profiles_multiref')
                    .delete()
                    .eq('profileid', `${selectedRow[0]}`);

                if (response) {
                    console.log("Deleted profiles_multiref");
                    console.log(response);
                    // Delete profile tabulation multiref between profile and tabulations
                    const response2 = await supabase
                        .from('profilestabulations_multiref')
                        .delete()
                        .eq('phonebookprofileid', `${selectedRow[0]}`)
                        .select();
                    if (response2) {
                        //Now delete the phonebook profile itself
                        console.log(response2);
                        const response3 = await supabase
                            .from('phonebook_profiles')
                            .delete()
                            .eq('id', `${selectedRow[0]}`)
                            .select();
                        if (response3) {
                            //If we delete the selected row, we remove the selected row
                            removeIdFromSelectedRow(selectedRow[0]);
                            console.log("Deleted datas");
                            //console.log(response2);
                        }
                    }
                }
                updateTablePage();
            } else {
                console.log("Error in deleting in Phonebook - etablissement is not defined");
            }
        } else {
            console.log("Error in deleting in Phonebook - selectedRow is empty");
        }
        setLoading(false);






    }


    function MyModalForIconSelection(props) {
        return (<Modal {...props}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Choisir une icone
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IconPicker />
            </Modal.Body>
        </Modal>)
    }
    const nt_columns = [{
        name: '#',
        selector: row => row.num,
        sort: true
    },
    {
        name: 'Profile',
        selector: row => row.profile,
        sort: true
    },
    {
        name: 'Status',
        selector: row => row.status,
        sort: true
    },
    {
        name: 'Edit Tabulations',
        button: true,
        cell: row => (
            <Link to="/tabulationslist" state={{ "profileid": row.id, "etablissementid": row.etablissement[0]?.id }} style={{ textDecoration: 'none' }}>
                <IconField iconname={'edit'} icontype={'awsome'} initiales={"E"} style={{ fill: '#43a047' }} />
            </Link>)

    },
    {
        name: 'Edit',
        button: true,
        cell: row => (
            <Link to="/tabulations" state={{ "profileid": row.id, "etablissementid": row.etablissement[0]?.id }} style={{ textDecoration: 'none' }}>
                <IconField iconname={'edit'} icontype={'awsome'} initiales={"E"} style={{ fill: '#43a047' }} />
            </Link>)

    }

    ];

    const handlePageChange = page => {
        setLoading(true);

        const currentIndex = (page - 1) * sizePerPage;
        getPhonebookProfiles(currentIndex, currentIndex + sizePerPage - 1);

        setPage(page);
        setLoading(false);
    };

    //React data table component
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const currentIndex = (page - 1) * newPerPage;
        getPhonebookProfiles(currentIndex, currentIndex + newPerPage - 1);

        setSizePerPage(newPerPage);
        setPage(page);
        setLoading(false);
    };

    const handleOnSelectRow = ({ allSelected, selectedCount, selectedRows }) => {
        console.log("Selected row = ");
        console.log(selectedRows);
        console.log("All selected ? " + allSelected);
        console.log("Selected count = " + selectedCount);

        if (selectedRows && selectedRows[0] && selectedRows[0].id && selectedRows.length > 0) {
            setSelectedRow([selectedRows[0].id]);
            //   if(selectedRows && selectedRows[0]) setIconSelected({iconName: selectedRows[0].iconname, iconType: selectedRows[0].icontype});

        }
    };

    return (
        <>
            <AppNavBar />
            <Container >
                <ModalForCreateProfileMR
                    selectedRowValues={selectedRow}
                    phonebook={phonebookProfiles}
                    show={modalShow}
                    whenfinished={() => { console.log("create finished"); setModalShow(false); }}
                    onHide={() => setModalShow(false)}
                    onExited={() => { updateTablePage() }}
                    etablissement={etablissementId}
                />
                <ModalForEditProfileMR
                    selectedRowValues={selectedRow}
                    profileList={phonebookProfiles}
                    show={modalForEditShow}
                    whenfinished={() => { console.log("update finished"); setModalForEditShow(false); }}
                    onHide={() => setModalForEditShow(false)}
                    onExited={() => { updateTablePage() }} />

                <div class="d-flex">
                    <div class="col-lg-8">
                        <h1><Icon name="rocket" size={32} /> Profiles </h1>

                        <p class="lead">Profils globaux avec 4 tabulations</p>
                        <h2>Filtres</h2>
                        <Row>
                            <Col>
                                <SelectEtablissement onChange={setEtablissement} onChangeId={setEtablissementId} />
                            </Col>
                        </Row>

                        <div class='d-flex flex-row mb-3'>
                            <h4>Etablissement: {etablissement} - Id: {etablissementId} </h4>
                        </div>



                        <>
                            <Button size="sm" variant="outline-primary" onClick={() => { updateTablePage() }} ><ArrowClockwise size={16} /></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { setModalShow(true) }}><FilePlus size={16} /></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { if (selectedRow.length > 0) { setModalForEditShow(true); } else { alert("Aucune ligne n'est sélectionnée pour modification !") } }}><PencilSquare size={16} /></Button>
                            <Button size="sm" variant="outline-danger" onClick={() => { deleteItemInPhonebookProfiles() }}><Trash size={16} /></Button>
                            {loading ?
                                <div class="d-flex p-2 flex-column align-items-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div> : null}
                        </>
                        {phonebookProfiles ?
                            <DataTable
                                columns={nt_columns}
                                data={phonebookProfiles}
                                pagination
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalSize}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                selectableRows
                                selectableRowsSingle
                                onSelectedRowsChange={handleOnSelectRow}
                                selectableRowsHighlight
                                striped
                            /> : <div class="spinner-border text-primary" role="status">
                                <span class="sr-only"></span>
                            </div>}
                    </div>
                </div>
            </Container>

        </>

    );
};

export default PhonebookProfiles;