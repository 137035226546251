import React from "react";
import { useState, useEffect, useRef } from 'react'
import { supabase } from "../supabaseClient";
import { Button, Form, Modal, Row, Alert, ButtonGroup } from "react-bootstrap";
import { FilePlus } from 'react-bootstrap-icons';
import IconPicker from "../components/IconPicker";
import InputGroup from 'react-bootstrap/InputGroup';


const MyModalForCreatingPhoneItemMR = (props) => {
    const { selectedRowValues, phonebook, tabulationId, whenfinished } = props;
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const shortTelRef = useRef(null);
    const longTelRef = useRef(null);
    const sectionRef = useRef(null);
    const posteRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");

    const [iconSelected, setIconSelected] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    useEffect(() => {
        const fixCurrentRow = () => {
            let currentRow = null;
            if (selectedRowValues && selectedRowValues.length > 0 && phonebook) {
                //console.log("Selected Row Value = " + selectedRowValues[0])
                currentRow = phonebook.find((element) => element.id === selectedRowValues[0]);
                console.log(currentRow);
                if (currentRow) {
                    //console.log("(modalForCreate)Current Row = " + currentRow.id + " - " + currentRow.title + " - " + currentRow.subtitle + " - " + currentRow.numero_court + " - " + currentRow.numero + " - " + currentRow.iconname + " - " + currentRow.icontype + " - " + currentRow.ordre);
                    console.log(currentRow);
                } else {
                    currentRow = null;
                }
            };
            setCurrentRow(currentRow);
        }
        fixCurrentRow();
    }, [phonebook, selectedRowValues]);

    const getIconForm = () => {
        let anIcon = {};
        if (iconSelected) {
            anIcon = iconSelected;
        } else if (currentRow?.iconname && currentRow?.icontype) {
            anIcon = { iconName: currentRow?.iconname, iconType: currentRow?.icontype };
        } else {
            anIcon = { iconName: 'user-md', iconType: 'awsome' };
        }
        return anIcon;
    }

    const handleSubmitCreateTel = async (e) => {
        e.preventDefault();

        let aSection = sectionRef.current?.value;
        let aTitle = titleRef.current?.value;
        let aSubtitle = subtitleRef.current?.value;
        let aShortTel = shortTelRef.current?.value;
        let aLongTel = longTelRef.current?.value;
        let aPoste = posteRef.current?.value;

        let anIcon = getIconForm();

        createInPhonebook( aSection, aTitle, aSubtitle, aShortTel, aLongTel, aPoste, anIcon );
        
    }

    const createInPhonebook = async (aSection, aTitle, aSubtitle, aShortTel, aLongTel, aPoste, anIcon ) => {
        try {
            setErrorMsg("");
            setLoading(true);

            if (!aSection || !aTitle|| !aSubtitle || !anIcon) { 
                setErrorMsg("Informations manquantes !");
                console.log("Info manquante in tabulation_multiref with icon " + anIcon.iconName + " - " + anIcon.iconType + " - " + "Section = " + aSection + " - Title = " + aTitle + " - Subtilte = " + aSubtitle + " - LongNb = " + aLongTel + " - ShotNb =" + aShortTel + " - Pste = " + aPoste);

                setLoading(false);
                return;
            } 

            //Where to put this new item - First check if there is a selected row
            var ordreToInsert = 0;
            if (currentRow) {
                ordreToInsert = currentRow.ordre + 0.5;
            } else {
                setErrorMsg("Pas de ligne sélectionnée pour ajouter un numéro de téléphone - Ajouté en première position par défaut");
                setLoading(false);
                ordreToInsert = 0.5;
            };

            console.log("*Update phonebook in db with in tabulation " + tabulationId);
            if (tabulationId !== undefined) {
                //Create a global item first in global phonebook and add the MR
                const { data: insertData, error: insertError } = await supabase.from('globalphonebook').insert([{
                    currentversion: 1,
                    etablissementname: "chu-angers",
                    etablissementid: 100000,
                    title: aTitle,
                    subtitle: aSubtitle,
                    iconname: anIcon.iconName,
                    icontype: anIcon.iconType,
                    numero: aLongTel,
                    numero_court: aShortTel,
                    poste: aPoste,
                }]).select();

                if (insertError) {
                    console.log("Error in adding phone number in Phonebook tabulation ");
                    console.log(insertError);
                    setErrorMsg(insertError);

                } else if (insertData) {
                    console.log(insertData);
                    console.log("TabID inserted = " + insertData[0]?.id);

                    if (insertData[0]?.id) {
                        //Add a tabulation_mutiref
                        console.log("Add a tabulation_multiref with tabulationid = " + tabulationId + " and phonebookid = " + insertData[0]?.id + " and ordre = " + ordreToInsert + " and icon = " + anIcon.iconName + " - " + anIcon.iconType + " - " + "Section = " + aSection + " - Title = " + aTitle + " - Subtilte = " + aSubtitle + " - LongNb = " + aLongTel + " - ShotNb =" + aShortTel + " - Pste = " + aPoste);

                        const { data: insertData2, error: insertError2 } = await supabase.from('tabulations_multiref').insert([{
                            currentversion: 1,
                            sectiontitle: aSection,
                            title: aTitle,
                            subtitle: aSubtitle,
                            iconname: anIcon.iconName,
                            icontype: anIcon.iconType,
                            numero: aLongTel,
                            numero_court: aShortTel,
                            poste: aPoste,
                            ordre: ordreToInsert,
                            tabulationsid: tabulationId,
                            phonebookid: insertData[0]?.id,
                        }]).select();

                        if (insertError2) {
                            console.warn(insertError2)
                            setErrorMsg(insertError2);
                        } else if (insertData2) {
                            console.log(insertData2);
                        }
                    } else {
                        console.log("Error in adding phone number in Phonebook tabulation");
                    }

                }
            } else {
                console.log("Error in adding phone number in Phonebook tabulation " + tabulationId + " is undefined");
            }
        } catch (error) {
            setErrorMsg(error);
        }
        setLoading(false);
        if (whenfinished) whenfinished();
    };


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Ajouter un numéro de téléphone to tab {tabulationId}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmitCreateTel}>
                    <Row className="mb-3">
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Section</InputGroup.Text>
                            <Form.Control type="text" ref={sectionRef} defaultValue={currentRow ? currentRow.sectiontitle : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            {/* <InputGroup.Text id="basic-addon1"><IconField iconname={getIconForm().iconName} icontype={getIconForm().iconType} /></InputGroup.Text> */}
                            <InputGroup.Text id="basic-addon2">Titre</InputGroup.Text>
                            <Form.Control type="text" ref={titleRef} defaultValue={currentRow ? currentRow.title : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            {/* <InputGroup.Text id="basic-addon1"><IconField iconname={getIconForm().iconName} icontype={getIconForm().iconType} /></InputGroup.Text> */}
                            <InputGroup.Text id="basic-addon3">Sous titre</InputGroup.Text>
                            <Form.Control type="text" ref={subtitleRef} defaultValue={currentRow ? currentRow.subtitle : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon4">Téléphone Court</InputGroup.Text>
                            <Form.Control type="text" ref={shortTelRef} defaultValue={currentRow ? currentRow.numero_court : null} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon5">Téléphone Long</InputGroup.Text>
                            <Form.Control type="text" ref={longTelRef} defaultValue={currentRow ? currentRow.numero : null} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon5">Poste fixe</InputGroup.Text>
                            <Form.Control type="text" ref={posteRef} defaultValue={currentRow ? currentRow.poste : null} />
                        </InputGroup>

                        <IconPicker callback={
                            (icon) => {
                                console.log("Choosed " + icon.iconName + " - " + icon.iconType);
                                setIconSelected(icon);
                            }
                        } initialIcon={getIconForm()} />
                    </Row>
                    {errorMsg && (
                        <Alert
                            variant="danger"
                            onClose={() => setErrorMsg("")}
                            dismissible>
                            {errorMsg}
                        </Alert>
                    )}
                    <div className="text-center mt-2">
                        <ButtonGroup>
                            <Button disabled={loading} type="submit" className="w-50"><FilePlus size={32} />Ajouter</Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default MyModalForCreatingPhoneItemMR;