import React from "react";
import { useAuth } from "../context/AuthProvider";
import { useState, useEffect, useRef } from 'react'
import { supabase } from "../supabaseClient";
import { Button, Form, Modal, Row, Alert, ButtonGroup } from "react-bootstrap";
import SelectEtablissement from "../components/SelectEtablissement";
import { FilePlus } from 'react-bootstrap-icons';
import InputGroup from 'react-bootstrap/InputGroup';


const MyModalForCreatingProfileMR = (props) => {
    const { selectedRowValues, phonebook, whenfinished, etablissement, profile, tabulation} = props;
    const profileRef = useRef(null);
    const statusRef = useRef(null);
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [currentRow, setCurrentRow] = useState(null);

    useEffect(() => {
        const fixCurrentRow = () => {
            let currentRow = null;
            console.log("Selected Row Value = " + selectedRowValues);
            if( selectedRowValues && selectedRowValues.length > 0 && phonebook){
                //console.log("Selected Row Value = " + selectedRowValues[0])
                currentRow = phonebook.find((element) => element.id === selectedRowValues[0]);
                if(currentRow){
                    //console.log("(modalForCreate)Current Row = " + currentRow.id + " - " + currentRow.title + " - " + currentRow.subtitle + " - " + currentRow.numero_court + " - " + currentRow.numero + " - " + currentRow.iconname + " - " + currentRow.icontype + " - " + currentRow.ordre);
                    console.log(currentRow);    
                } else {
                    currentRow = null;
                    console.log("No Row selected");    
                }
            };
            setCurrentRow(currentRow);
        }
        fixCurrentRow();
    }, [phonebook, selectedRowValues]);


    const handleSubmitCreateTel = async (e) => {
        e.preventDefault();
        createInPhonebook();
        if(whenfinished) whenfinished();
    }

    const createInPhonebook = async () => {
        try {
            setErrorMsg("");
            setLoading(true);

            if (!profileRef.current?.value || !statusRef.current?.value) {
                setErrorMsg("Informations manquantes !");
                setLoading(false);
                return;
            }


            if ( profileRef !== undefined || statusRef !== undefined) {
                let { data, error } = await supabase
                    .from('phonebook_profiles')
                    .insert({ 
                            'profile': profileRef.current?.value, 
                            'status': statusRef.current?.value, 
                            'currentversion': 1
                         })
                    .select()
    
                if (error) {
                    console.warn(error)
                } else if (data) {
                    console.log(data);

                    //Next add the profile to the etablissement
                    console.log("Add to Etablissement list this profile = " + etablissement + " - Profile id " + data[0].id);
                    let { databis, errorbis } = await supabase
                    .from('profiles_multiref')
                    .insert({
                        'etablissementid': etablissement,
                        'profileid': data[0].id
                    });
                    if (errorbis) {
                        console.warn(errorbis)
                    } else if (databis) {
                        console.log(databis);
                    }
                }
            } else {
                console.log("Error in adding profile ");
            }
        } catch (error) {
            setErrorMsg(error);
        }
        setLoading(false);
    };


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Ajouter un profil
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmitCreateTel}>
                    <Row className="mb-3">

                        <InputGroup className="mb-3">
                        {/* <InputGroup.Text id="basic-addon1"><IconField iconname={getIconForm().iconName} icontype={getIconForm().iconType} /></InputGroup.Text> */}
                        <InputGroup.Text id="basic-addon2">Profile</InputGroup.Text>
                        <Form.Control type="text" ref={profileRef} defaultValue={currentRow ? currentRow.profile : null} required />
                        </InputGroup>

                        <InputGroup className="mb-3">
                        {/* <InputGroup.Text id="basic-addon1"><IconField iconname={getIconForm().iconName} icontype={getIconForm().iconType} /></InputGroup.Text> */}
                        <InputGroup.Text id="basic-addon3">Status</InputGroup.Text>
                        <Form.Control type="text" ref={statusRef} defaultValue={currentRow ? currentRow.status : null} required />
                        </InputGroup>
                    </Row>
                    {errorMsg && (
                        <Alert
                            variant="danger"
                            onClose={() => setErrorMsg("")}
                            dismissible>
                            {errorMsg}
                        </Alert>
                    )}
                    <div className="text-center mt-2">
                        <ButtonGroup>
                            <Button disabled={loading} type="submit" className="w-50"><FilePlus size={32}/>Ajouter</Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default MyModalForCreatingProfileMR;