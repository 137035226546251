import React from "react";
import { useAuth } from "../context/AuthProvider";
import { useState, useEffect, useRef } from 'react'
import { supabase } from "../supabaseClient";
import AppNavBar from '../components/AppNavBar'
import { Container, Button, Card, Form, Modal, Row, Alert, ButtonGroup, Col } from "react-bootstrap";
import SelectEtablissement from "../components/SelectEtablissement";
import SelectProfile from "../components/SelectProfile";
import SelectTabulation from "../components/SelectTabulation";
import { ArrowUpShort, ArrowDownShort, ArrowClockwise, ListOl, FilePlus, Trash, PencilSquare, BorderLeft, Pencil } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import IconField from '../components/IconField';
import IconPicker from "../components/IconPicker";
import InputGroup from 'react-bootstrap/InputGroup';

const MyModalForAddingTabulation = (props) => {
    const { selectedrowvalues, tabulationsofprofile, whenfinished, etablissement, profile, whenCreateTabIsPushed, whenEditingTabIsPressed } = props;
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");

    const [currentRow, setCurrentRow] = useState(null);

    const [tabulations, setTabulations] = useState(null);
    const [totalSize, setTotalSize] = useState(0);
    const [selectedRow, setSelectedRow] = useState([]);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(1);


    useEffect(() => {
        const fixCurrentRow = () => {
            let currentRow = null;
            if (selectedrowvalues && selectedrowvalues.length > 0 && tabulationsofprofile) {
                console.log("Selected Row Value = " + selectedrowvalues[0])
                console.log("Tabulation of Profile = " + tabulationsofprofile);
                currentRow = tabulationsofprofile.find((element) => element.id === selectedrowvalues[0]);
                console.log(currentRow);
                if (currentRow) {
                    //console.log("(modalForChoosingTabulation)Current Row = " + currentRow.id + " - " + currentRow.title + " - " + currentRow.dbstring + " - " + currentRow.iconname + " - " + currentRow.icontype + " - " + currentRow.ordre);
                    console.log("Current Tabulation=");
                    console.log(currentRow);
                } else {
                    currentRow = null;
                }
            };
            setCurrentRow(currentRow);
        }
        fixCurrentRow();
    }, [tabulationsofprofile, selectedrowvalues]);

    const handleSubmitChooseTabulation = async (e) => {
        e.preventDefault();
        chooseTabulation();
        if (whenfinished) whenfinished();
    }

    /**
            * Get the size of the profile table when the table is updated
            */
    const getTotalSize = async () => {
        //Get total size
        if (etablissement !== undefined) {
            setLoading(true)
            console.log("(Tabulations) Get total Size of phonebook in db with profile " + profile + " and etablissement " + etablissement)
            let { data, error, count } = await supabase.from('tabulations')
                .select('*', { count: 'exact', head: true })
            console.log("Total size = " + count + " - " + data + " - " + error);
            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
            }
            setTotalSize(count);
            console.log("Total size = " + count);
            setLoading(false);
        } else {
            console.log("Error in gettabulations - profile " + profile);
        }

    }

    /**
         * Get the phonebook table from the database with pagination
         * @param {*} firstIndex 
         * @param {*} lastIndex 
         */
    const gettabulations = async (firstIndex, lastIndex) => {

        //Get the total size of the profile table for pagination
        getTotalSize();

        setLoading(true)
        console.log("(Tabulations)Get phonebook in db with profile " + profile);
        if (etablissement !== undefined) {
            let { data, error } = await supabase
                .from('tabulations')
                .select('*')
                // .order('ordre', { ascending: true })
                .range(firstIndex, lastIndex)

            if (error) {
                console.warn(error)
            } else if (data) {
                //console.log(data);
                data.map((item, index) => { item["num"] = firstIndex + index + 1; return item; });
                setTabulations(data);
                console.log("Row for tabulations:");
                console.log(data);
            }
        } else {
            console.log("Error in getGlobalPhonebook - etablissement " + etablissement + " is undefined");
        }

        setLoading(false);
    }

    const updateTablePage = () => {
        const currentIndex = (page - 1) * sizePerPage;
        gettabulations(currentIndex, currentIndex + sizePerPage - 1);
    };

    useEffect(() => {
        updateTablePage();
    }, [etablissement]);


    const chooseTabulation = async () => {
        console.log("(ModalForChoosingTabulation)Tabulation choosen = " + selectedRow[0]);
        console.log("for etablissement " + etablissement + " with profile " + profile);

        try {
            setErrorMsg("");
            setLoading(true);

            if (!selectedRow && selectedRow.length === 0) {
                setErrorMsg("Informations manquantes !");
                setLoading(false);
                return;
            }

            var anOrdre = 1;
            if (currentRow) {
                //Change supabase ordre. If ordre is an integer wihtout decimal, we remove 1.5 to have a decimal number. If not, we remove
                var previousOrdre = currentRow.ordre;
                var delta = 1.5;
                if (previousOrdre % 1 !== 0) { delta = 1.0; }
                anOrdre = previousOrdre + delta;
            };


            let { data, error } = await supabase
                .from('profilestabulations_multiref')
                .insert({
                    'phonebookprofileid': profile,
                    'tabulationid': parseInt(selectedRow[0]),
                    'ordre': anOrdre
                })
                .select();

            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
            }

        } catch (error) {
            setErrorMsg(error);
        }
        setLoading(false);
    };


    const nt_columns = [{
        name: '#',
        selector: row => row.num,
        sort: true
    },
    {
        cell: row => <IconField iconname={row.iconname} icontype={row.icontype} initiales={row.initiales} style={{ fill: '#43a047' }} />,
        width: '56px', // custom width for icon button
        style: {
            borderBottom: '1px solid #FFFFFF',
            marginBottom: '-1px',
        },
    }, {
        name: 'Titre',
        selector: row => row.title,
        sort: true
    },
    {
        name: 'DbString',
        selector: row => row.dbstring,
        sort: true
    },
    {
        name: 'usemultiref',
        selector: row => { 
            if(row.usemultiref) {
                return "true";
            }  else {
                return "false";
        }},
        sort: true
    },

    ];

    const handlePageChange = page => {
        setLoading(true);

        const currentIndex = (page - 1) * sizePerPage;
        gettabulations(currentIndex, currentIndex + sizePerPage - 1);

        setPage(page);
        setLoading(false);
    };

    //React data table component
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const currentIndex = (page - 1) * newPerPage;
        gettabulations(currentIndex, currentIndex + newPerPage - 1);

        setSizePerPage(newPerPage);
        setPage(page);
        setLoading(false);
    };

    /**
     * When the edit button is pushed, get the tabulation selected in the table and pass it to the main class tabulationList to pass it to the modal for edit tabulation.
     */
    const onPushEditTabulation = () => {
        //Get current tabulation in table when selected.
        if(selectedRow && selectedRow.length > 0 && tabulations ){
            console.log("(ModalForChoosingTabulation)Tabulation selected = " + selectedRow[0]);
            //Get full tabulation selected
            let tabulationSelected = tabulations.find((element) => element.id === selectedRow[0]);

            if(whenEditingTabIsPressed && tabulationSelected) {
                whenEditingTabIsPressed(tabulationSelected); 
            } else {
                console.log("Error in getting tabulation selected");
            }

        } else {
            console.log("Error in getting tabulation selected");
        }
    }

    const handleOnSelectRow = ({ allSelected, selectedCount, selectedRows }) => {
        console.log("Selected row = ");
        console.log(selectedRows);
        console.log("All selected ? " + allSelected);
        console.log("Selected count = " + selectedCount);

        if (selectedRows && selectedRows[0] && selectedRows[0].id && selectedRows.length > 0) {
            setSelectedRow([selectedRows[0].id]);
            //   if(selectedRows && selectedRows[0]) setIconSelected({iconName: selectedRows[0].iconname, iconType: selectedRows[0].icontype});

        }
    };

    return (
        <>
        
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Remplacer ou créer une tabulation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmitChooseTabulation}>
                    <Row className="mb-3">

                        <div>Etablissement = {etablissement}</div>
                        <div>Profile = {profile}</div>
                        <div>{currentRow ? currentRow.title : null}</div>
                        <>
                                                </>

                    </Row>
                    <Row>
                        <div className="d-flex flex-row bd-highlight mb-3">
                        <Button size="sm" variant="outline-primary" onClick={() => { updateTablePage() }} ><ArrowClockwise size={16} /></Button>
                        <Button size="sm" variant="outline-primary" onClick={() => { if(whenCreateTabIsPushed) whenCreateTabIsPushed(); }}><FilePlus size={16} /></Button>
                        <Button size="sm" variant="outline-primary" onClick={() => { onPushEditTabulation() }}><PencilSquare size={16} /></Button>
                        <Button size="sm" variant="outline-danger" onClick={() => {  }}><Trash size={16} /></Button>
                        </div>
                    </Row>
                    
                    <Row>
                        {tabulations ?
                            <DataTable
                                columns={nt_columns}
                                data={tabulations}
                                pagination
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={totalSize}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                selectableRows
                                selectableRowsSingle
                                onSelectedRowsChange={handleOnSelectRow}
                                selectableRowsHighlight
                                striped
                            /> : <div class="spinner-border text-primary" role="status">
                                <span class="sr-only"></span>
                            </div>}
                    </Row>
                    {errorMsg && (
                        <Alert
                            variant="danger"
                            onClose={() => setErrorMsg("")}
                            dismissible>
                            {errorMsg}
                        </Alert>
                    )}
                    <div className="text-center mt-2">
                        <ButtonGroup>
                            <Button disabled={loading} type="submit" className="w-50"><FilePlus size={32} />Remplacer</Button>
                        </ButtonGroup>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
        </>

    );
}

export default MyModalForAddingTabulation;