import React from "react";
import { useState, useEffect, useRef } from 'react'
import { supabase } from "../supabaseClient";
import AppNavBar from '../components/AppNavBar'
import { Container, Button, Form, Modal, Row, Col } from "react-bootstrap";
import SelectEtablissement from "../components/SelectEtablissement";
import SelectProfile from "../components/SelectProfile";
import SelectTabulation from "../components/SelectTabulation";
import { ArrowUpShort, ArrowDownShort, ArrowClockwise, ListOl, FilePlus, Trash, PencilSquare } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import IconField from '../components/IconField';
import IconPicker from "../components/IconPicker";
import ModalForEditRow from '../pages/modalForEditRow';
import MyModalForCreatingPhoneItemMR from '../pages/modalForCreatePhoneItemMR';
import InputGroup from 'react-bootstrap/InputGroup';

const PhonebookTab = (props) => {
    const [tabulationId, setTabulationId] = useState(props.TabulationId)
    const [loading, setLoading] = useState(false)
    const [phonebook, setPhonebook] = useState(null);
    const [totalSize, setTotalSize] = useState(0);
    const [selectedRow, setSelectedRow] = useState([]);
    const [completeSelectedRow, setCompleteSelectedRow] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const packIconRef = useRef(null);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [modalForEditShow, setModalForEditShow] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [searchText, setSearchText] = useState("");
    const [globalphonebook, setGlobalPhonebook] = useState(null);
    const [globalPhonebookSize, setGlobalPhonebookSize] = useState(0);
    const [globalPhonebookSelectedRow, setGlobalPhonebookSelectedRow] = useState([]);
    const [globalPhonebookSizePerPage, setGlobalPhonebookSizePerPage] = useState(10);
    const [globalPhonebookPage, setGlobalPhonebookPage] = useState(1);

    /**
         * Get the size of the profile table when the table is updated
         */
    const getTotalSize = async () => {
        //Get total size
        if (tabulationId !== undefined) {
            setLoading(true)
            console.log("Get total size of phonebook in db with tabulation " + tabulationId);
            let { data, error, count } = await supabase.from('tabulations_multiref')
                .select('*,globalphonebook!inner(*)', { count: 'exact', head: true })
                .eq('tabulationsid', tabulationId);
            console.log("Total size = " + count + " - " + data + " - " + error);
            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
            }
            setTotalSize(count);
            console.log("Total size = " + count);
            setLoading(false);
        } else {
            console.log("Error in getPhonebook - tabulation: " + tabulationId + " is undefined");
        }

    }

    /**
         * Get the phonebook table from the database with pagination
         * @param {*} firstIndex 
         * @param {*} lastIndex 
         */
    const getPhonebook = async (firstIndex, lastIndex) => {

        //Get the total size of the profile table for pagination
        getTotalSize();

        setLoading(true)
        if (tabulationId !== undefined) {
            console.log("(Phonebook Tab)Get phonebook in db with in tabulation " + tabulationId);
            let { data, error } = await supabase.from('tabulations_multiref')
                .select('*,globalphonebook!inner(*)')
                .eq('tabulationsid', tabulationId)
                .order('ordre', { ascending: true })
                .range(firstIndex, lastIndex);

            if (error) {
                console.warn(error)
            } else if (data) {
                data.map((item, index) => { item["num"] = firstIndex + index + 1; return item; });
                console.log("PhonebookTab data - " + tabulationId);
                console.log(data);
                setPhonebook(data);
            }
        } else {
            console.log("Error in getPhonebook tabulation " + tabulationId + " is undefined");
        }

        setLoading(false);
    }

    const updateTablePage = () => {
        const currentIndex = (page - 1) * sizePerPage;
        getPhonebook(currentIndex, currentIndex + sizePerPage - 1);
    };

    useEffect(() => {
        updateTablePage();
        console.log("Update PhoneBook Tab with tabulationId = " + tabulationId);
    }, [tabulationId]);


    /**
             * Get the size of the global phonebook table
             */
    const getGlobalPhonebookTotalSize = async () => {
        //Get total size
        setLoading(true)
        let aSearch = `%${searchInput}%`;

        let query = supabase.from('globalphonebook')
            .select('*', { count: 'exact', head: true });

        if (searchInput && searchInput !== "" && searchInput.length >= 3) {
            query = query.or(`title.ilike.${aSearch}, numero.ilike.${aSearch}, numero_court.ilike.${aSearch}, poste.ilike.${aSearch}`);
            //            query = query.or(`title.ilike.${aSearch}`);
        }
        let { data, error, count } = await query;

        console.log("Total size = " + count + " - " + data + " - " + error);
        if (error) {
            console.warn(error)
        } else if (data) {
            console.log(data);
        }
        setGlobalPhonebookSize(count);
        console.log("Total size = " + count);
        setLoading(false);

    }

    /**
         * Get the phonebook table from the database with pagination
         * @param {*} firstIndex 
         * @param {*} lastIndex 
         */
    const getGlobalPhonebook = async (firstIndex, lastIndex) => {

        //Get the total size of the profile table for pagination
        getGlobalPhonebookTotalSize();

        setLoading(true)
        if (tabulationId !== undefined) {
            console.log("(Phonebook Tab)Get phonebook in db with in tabulation " + tabulationId);
            let aSearch = `%${searchInput}%`;

            let query = supabase.from('globalphonebook')
                .select('*')
                .range(firstIndex, lastIndex);

            if (searchInput && searchInput !== "" && searchInput.length >= 3) {
                query = query.or(`title.ilike.${aSearch}, numero.ilike.${aSearch}, numero_court.ilike.${aSearch}, poste.ilike.${aSearch}`);
                //                query = query.or(`title.ilike.${aSearch}`);
            }

            let { data, error } = await query;

            if (error) {
                console.warn(error)
            } else if (data) {
                data.map((item, index) => { item["num"] = firstIndex + index + 1; return item; });
                console.log(data);
                setGlobalPhonebook(data);
            }
        } else {
            console.log("Error in getPhonebook tabulation " + tabulationId + " is undefined");
        }

        setLoading(false);
    }

    useEffect(() => {
        updateGlobalTablePage();
    }, [searchInput]);


    const updateGlobalTablePage = () => {
        const currentIndex = (globalPhonebookPage - 1) * globalPhonebookSizePerPage;
        getGlobalPhonebook(currentIndex, currentIndex + globalPhonebookSizePerPage - 1);
    };

    /**
            * Update one value of a row
            * @param {*} itemId Id of the order to update
            * @param {*} dataField Data field to update
            * @param {*} newOrderValue New value of the order
            */
    const updateInPhonebook = async (itemId, dataField, newOrderValue) => {

        setLoading(true)
        console.log("*Update phonebook in db with tabulation " + tabulationId);
        console.log("ItemID = " + itemId + "in row = " + dataField + "and value = " + newOrderValue);
        if (tabulationId !== undefined) {
            if (dataField === "numero_long_court") {
                console.log("Update numero_long_court = " + newOrderValue);
            } else {
                let { data, error } = await supabase
                    .from('phonebook')
                    .update({ [dataField]: newOrderValue })
                    .eq('id', itemId)
                    .select()

                if (error) {
                    console.warn(error)
                } else if (data) {
                    console.log(data);
                }
            }

            updateTablePage();
        } else {
            console.log("Error in Updating " + dataField + " in Phonebook " + tabulationId + " is undefined");
        }
        setLoading(false);
    }

    /**
                * Update one value of a row
                * @param {*} currentRow The current phonebook row with multiref tabulation
                * @param {*} dataField Data field to update
                * @param {*} newOrderValue New value of the order
                */
    const updateInTabulationsMultiRef = async (currentRow, dataField, newOrderValue) => {

        setLoading(true)
        console.log("(PhonebookTab)Update InTabulationsMultiRef " + currentRow.id);
        console.log("MultiRef Tab id = " + currentRow.id + "in row = " + dataField + "and value = " + newOrderValue);
        if (tabulationId !== undefined) {
            let { data, error } = await supabase
                .from('tabulations_multiref')
                .update({ [dataField]: newOrderValue })
                .eq('id', currentRow.id)
                .select()

            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
            }

            updateTablePage();
        } else {
            console.log("Error in Updating " + dataField + " in Phonebook " + tabulationId + " is undefined");
        }
        setLoading(false);
    }

    /**
     * Remove id from the selected row
     * @param {*} id
     * @returns
     * 
     */
    const removeIdFromSelectedRow = (id) => {
        setSelectedRow(row => row.filter(item => item.id !== id));
        setCompleteSelectedRow(row => row.filter(item => item.id !== id));
    }

    /**
            * Delete one row in phonebook
            * @param {*} itemId Id of the order to update
            */
    const deleteItemInPhonebook = async (itemId) => {
/*
        setLoading(true)
        if (selectedRow.length > 0) {
            if (tabulationId !== undefined) {
                console.log("Delete row " + selectedRow[0]);
                let { data, error } = await supabase
                    .from('phonebook, tabulations(*)')
                    .delete()
                    .eq('tabulation.id', tabulationId)
                    .eq('id', `${selectedRow[0]}`);

                if (error) {
                    console.warn(error)
                } else if (data) {
                    console.log("Deleted datas");
                    console.log(data);
                    //If we delete the selected row, we remove the selected row
                    removeIdFromSelectedRow(selectedRow[0]);
                }
                updateTablePage();
            } else {
                console.log("Error in deleting in Phonebook");
            }
        }
        setLoading(false);
        */
    }

    /**
            * Delete one row in phonebook
            * @param {*} itemId Id of the order to update
            */
    const deleteItemInTabulationsMultiRef = async (itemId) => {

        setLoading(true)
        if (selectedRow.length > 0) {
            if (tabulationId !== undefined) {
                console.log("Delete row " + selectedRow[0]);
                let { data, error } = await supabase
                    .from('tabulations_multiref')
                    .delete()
                    .eq('tabulationsid', tabulationId)
                    .eq('id', `${selectedRow[0]}`);

                if (error) {
                    console.warn(error)
                } else if (data) {
                    console.log("Deleted datas");
                    console.log(data);
                    //If we delete the selected row, we remove the selected row
                    removeIdFromSelectedRow(selectedRow[0]);
                }
                updateTablePage();
            } else {
                console.log("Error in deleting in Phonebook");
            }
        }
        setLoading(false);
    }

    const orderTabulationsMultiRef = async () => {
        console.log("Order Tabulations MR");
        setLoading(true)
        if (tabulationId !== undefined) {
            const { data, error } = await supabase.rpc('reorder_tabulationsmultiref', { one_tabulationid: tabulationId });
            if (error) {
                console.warn(error)
            } else if (data) {
                console.log(data);
            }
            updateTablePage();
        } else {
            console.log("Error tabulation " + tabulationId + " is undefined");
        }

        setLoading(false);
    }

    const handleSetRowUp = () => {
        console.log("Set row up");
        if (selectedRow.length > 0) {
            console.log("Move Up row " + selectedRow[0]);
            console.log(phonebook);
            const currentRow = phonebook.find((element) => element.id === selectedRow[0]);
            console.log(currentRow);
            if (currentRow) {
                //Change supabase ordre. If ordre is an integer wihtout decimal, we remove 1.5 to have a decimal number. If not, we remove
                var previousOrdre = currentRow.ordre;
                var delta = 1.5;
                if (previousOrdre % 1 !== 0) { delta = 1.0; }

                //Change in supabase ordre
                updateInTabulationsMultiRef(currentRow, "ordre", previousOrdre - delta);
                //Update phonebook to maintain the order
                //orderPhonebook();
            } else { console.log("Current row is undefined"); }
        }
    }

    const handleSetRowDown = () => {
        console.log("Set row down");
        if (selectedRow.length > 0) {
            console.log("Move Down row " + selectedRow[0]);
            console.log(phonebook);
            const currentRow = phonebook.find((element) => element.id === selectedRow[0]);
            console.log(currentRow);
            if (currentRow) {
                var previousOrdre = currentRow.ordre;
                var delta = 1.5;
                var modulo = previousOrdre % 1;
                if (modulo !== 0) { delta = 1.0; }
                //Change in supabase ordre
                updateInTabulationsMultiRef(currentRow, "ordre", previousOrdre + delta);
                //Update phonebook to maintain the order
                //orderPhonebook();
            } else { console.log("Current row is undefined"); }
        }
    }



    const nt_columns = [{
        name: '#',
        selector: row => row.num,
        sort: true
    },
    {
        name: 'Section',
        selector: row => row.sectiontitle,
        sort: true
    },
    {
        cell: row => <IconField iconname={row.iconname} icontype={row.icontype} initiales={row.initiales} style={{ fill: '#43a047' }} />,
        width: '56px', // custom width for icon button
        style: {
            borderBottom: '1px solid #FFFFFF',
            marginBottom: '-1px',
        },
    },
    {
        name: 'Titre',
        selector: row => row.title,
        sort: true
    },
    {
        name: 'Sous Titre',
        selector: row => row.subtitle,
        sort: true
    },
    {
        name: 'Numéro Ref',
        selector: row => row.globalphonebook.numero_court ? row.globalphonebook.numero_court : row.globalphonebook.numero,
        sort: true
    },
    {
        name: 'Numéro Abs',
        selector: row => row.numero_court ? row.numero_court : row.numero,
        sort: true
    },
    {
        name: 'Poste',
        selector: row => row.poste ? row.poste : 'vide',
        sort: true
    },
    {
        name: 'Ordre',
        selector: row => row.ordre,
        sort: true
    },
        // {
        //     name: 'OrdreMR',
        //     selector: row => row?.tabulations_multiref[0]?.ordre ? row.tabulations_multiref[0]?.ordre : 0,
        //     sort: true
        // }

    ];

    const handlePageChange = page => {
        setLoading(true);

        const currentIndex = (page - 1) * sizePerPage;
        getPhonebook(currentIndex, currentIndex + sizePerPage - 1);

        setPage(page);
        setLoading(false);
    };

    //React data table component
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const currentIndex = (page - 1) * newPerPage;
        getPhonebook(currentIndex, currentIndex + newPerPage - 1);

        setSizePerPage(newPerPage);
        setPage(page);
        setLoading(false);
    };

    const handleOnSelectRow = ({ allSelected, selectedCount, selectedRows }) => {
        console.log("Selected row = ");
        console.log(selectedRows);
        console.log("All selected ? " + allSelected);
        console.log("Selected count = " + selectedCount);

        if (selectedRows && selectedRows[0] && selectedRows[0].id && selectedRows.length > 0) {
            setSelectedRow([selectedRows[0].id]);
            setCompleteSelectedRow(selectedRows[0]);
        }
    };

    //For global Phonebook
    const globalpb_columns = [{
        name: '#',
        selector: row => row.num,
        sort: true
    },
    {
        name: 'Titre',
        selector: row => row.title,
        sort: true
    },
    {
        name: 'Sous Titre',
        selector: row => row.subtitle,
        sort: true
    },
    {
        name: 'Global Numéro',
        selector: row => row.numero_court ? row.numero_court : row.numero,
        sort: true
    },
    {
        name: 'Poste fixe',
        selector: row => row.poste,
        sort: true
    },
    ];

    const handleGlobalPhoneBookPageChange = page => {
        setLoading(true);

        const currentIndex = (page - 1) * globalPhonebookSizePerPage;
        getGlobalPhonebook(currentIndex, currentIndex + globalPhonebookSizePerPage - 1);

        setGlobalPhonebookPage(page);
        setLoading(false);
    };

    //React data table component
    const handleGlobalPhoneBookPerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const currentIndex = (page - 1) * newPerPage;
        getPhonebook(currentIndex, currentIndex + newPerPage - 1);

        setGlobalPhonebookSizePerPage(newPerPage);
        setGlobalPhonebookPage(page);
        setLoading(false);
    };

    const handleGlobalPhoneBookOnSelectRow = ({ allSelected, selectedCount, selectedRows }) => {
        console.log("Selected row = ");
        console.log(selectedRows);
        console.log("All selected ? " + allSelected);
        console.log("Selected count = " + selectedCount);

        if (selectedRows && selectedRows[0] && selectedRows[0].id && selectedRows.length > 0) {
            setGlobalPhonebookSelectedRow([selectedRows[0].id]);
            console.log("Selected row = " + selectedRows[0].id);
        }
    };

    const insertItemInMultiRefTable = () => {
        console.log("Add tabulation multiref");
        setLoading(true);
        if (globalPhonebookSelectedRow.length > 0) {
            console.log("Add " + globalPhonebookSelectedRow[0]);
            const currentRow = globalphonebook.find((element) => element.id === globalPhonebookSelectedRow[0]);
            console.log(currentRow);

            let ordre = 1;
            let section = "";
            if (currentRow) {

                //Check if a row is selected on the table on top
                if (selectedRow.length > 0) {
                    const topRow = phonebook.find((element) => element.id === selectedRow[0]);
                    console.log(topRow);
                    if (topRow) {
                        ordre = topRow.ordre + 0.5;
                        section = topRow.section;
                    }
                }

                //Add a tabulation_mutiref
                supabase.from('tabulations_multiref').insert([{
                    currentversion: currentRow.currentversion,
                    sectiontitle: currentRow.section,
                    title: currentRow.title,
                    subtitle: currentRow.subtitle,
                    iconname: currentRow.iconname,
                    icontype: currentRow.icontype,
                    numero: currentRow.numero,
                    initiales: currentRow.initiales,
                    numero_court : currentRow.numero_court,
                    secretaire: currentRow.secretaire,
                    poste: currentRow.poste,
                    ordre: ordre,
                    tabulationsid: tabulationId,
                    phonebookid: currentRow.id
                }]).select().then((data) => {
                    console.log(data);
                    updateTablePage();
                }).catch(error => {
                    console.warn(error);
                });
            } else { console.log("Current row is undefined"); }
        }
        setLoading(false);
    }

    const handleUpdateEditRow = (row) => {
        setLoading(true);
        console.log(row);
//        console.log("Update ItemID = " + JSON.parse(row));
        try {
            supabase
                .from('tabulations_multiref')
                .update(row)
                .eq('id', selectedRow)
                .select()
                .then((data) => {
                    console.log(data);
                }).catch((error) => {
                    console.warn(error);
                });
        } catch (error) {
            console.log("Error in Updating Phonebook" + error);
        }
        setLoading(false);
    }

    return (
        <>
            <Container >
                <MyModalForCreatingPhoneItemMR
                    selectedRowValues={selectedRow}
                    phonebook={phonebook}
                    show={modalShow}
                    tabulationId={tabulationId}
                    whenfinished={() => { console.log("create finished"); setModalShow(false); }}
                    onHide={() => setModalShow(false)}
                    onExited={() => { updateTablePage() }}

                />
                 <ModalForEditRow
                    currentRow={completeSelectedRow}
                    updateRow={handleUpdateEditRow}
                    show={modalForEditShow}
                    whenfinished={() => { console.log("update finished"); setModalForEditShow(false); }}
                    onHide={() => setModalForEditShow(false)}
                    onExited={() => { updateTablePage() }} />

                <Row>
                    <Col>
                        <div>
                            <>
                                <Button size="sm" variant="outline-primary" onClick={() => { handleSetRowUp() }}><ArrowUpShort size={16} /></Button>
                                <Button size="sm" variant="outline-primary" onClick={() => { handleSetRowDown() }} ><ArrowDownShort size={16} /></Button>
                                <Button size="sm" variant="outline-primary" onClick={() => { updateTablePage() }} ><ArrowClockwise size={16} /></Button>
                                {/* <Button size="sm" variant="outline-primary" onClick={() => { orderPhonebook() }}><ListOl size={16}/></Button> */}
                                <Button size="sm" variant="outline-warning" onClick={() => { orderTabulationsMultiRef() }}><ListOl size={16} /></Button>
                                <Button size="sm" variant="outline-primary" onClick={() => { setModalShow(true) }}><FilePlus size={16} /></Button>
                                <Button size="sm" variant="outline-primary" onClick={() => { if (selectedRow.length > 0) { setModalForEditShow(true); } else { alert("Aucune ligne n'est sélectionnée pour modification !") } }}><PencilSquare size={16} /></Button>
                                <Button size="sm" variant="outline-danger" onClick={() => { deleteItemInTabulationsMultiRef() }}><Trash size={16} /></Button>
                                {loading ?
                                    <div class="d-flex p-2 flex-column align-items-center">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    </div> : null}
                            </>
                            {phonebook ?
                                <DataTable
                                    columns={nt_columns}
                                    data={phonebook}
                                    pagination
                                    progressPending={loading}
                                    paginationServer
                                    paginationTotalRows={totalSize}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    selectableRows
                                    selectableRowsSingle
                                    onSelectedRowsChange={handleOnSelectRow}
                                    selectableRowsHighlight
                                    striped
                                /> : <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only"></span>
                                </div>}
                        </div>
                    </Col>
                    <div >
                        <>
                            <Button size="sm" variant="outline-primary" onClick={() => { insertItemInMultiRefTable() }}><ArrowUpShort size={16} /></Button>
                            <Button size="sm" variant="outline-primary" onClick={() => { updateTablePage() }} ><ArrowClockwise size={16} /></Button>
                            {/* <Button size="sm" variant="outline-primary" onClick={() => { orderPhonebook() }}><ListOl size={16}/></Button> */}
                            <Button size="sm" variant="outline-primary" onClick={() => { if (selectedRow.length > 0) { setModalForEditShow(true); } else { alert("Aucune ligne n'est sélectionnée pour modification !") } }}><PencilSquare size={16} /></Button>
                            <Button size="sm" variant="outline-danger" onClick={() => { deleteItemInPhonebook() }}><Trash size={16} /></Button>
                            {loading ?
                                <div class="d-flex p-2 flex-column align-items-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div> : null}
                        </>
                        <>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"><IconField iconname={"search"} icontype={"material"} /></InputGroup.Text>
                                <Form.Control
                                    placeholder="Recherche"
                                    aria-label="Recherche"
                                    aria-describedby="basic-addon1"
                                    value={searchInput}
                                    onChange={(e) => { setSearchInput(e.target.value); }}
                                />
                                <InputGroup.Text id="basic-addon2"><Button variant='outline-light' onClick={() => { setSearchInput(""); }}><IconField iconname={"delete"} icontype={"feather"} /></Button></InputGroup.Text>
                            </InputGroup>
                        </>

                        {globalphonebook ?
                            <DataTable
                                columns={globalpb_columns}
                                data={globalphonebook}
                                pagination
                                progressPending={loading}
                                paginationServer
                                paginationTotalRows={globalPhonebookSize}
                                onChangeRowsPerPage={handleGlobalPhoneBookPerRowsChange}
                                onChangePage={handleGlobalPhoneBookPageChange}
                                selectableRows
                                selectableRowsSingle
                                onSelectedRowsChange={handleGlobalPhoneBookOnSelectRow}
                                selectableRowsHighlight
                                striped
                            /> : <div class="spinner-border text-primary" role="status">
                                <span class="sr-only"></span>
                            </div>}
                    </div>
                    <Col>

                    </Col>
                </Row>


            </Container>

        </>

    );
};

export default PhonebookTab;